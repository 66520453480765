<template>
  <v-container class="tw-mb-10">
    <v-row>
      <v-col cols="12">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-8"
        >
          Suggest an Activity
        </h2>
      </v-col>
    </v-row>
    <v-form @submit.prevent="save">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="Name of Activity"
              rules="required"
            >
              <j-text-field
                label="Name of Activity*"
                v-model="activity.name"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                placeholder="Cooking class, concert, dinner, hike, etc..."
                background-color="#fafafa"
                custom-classes="align-left"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="Message"
              rules="required|max:1000"
            >
              <j-textarea
                label="Post Message*"
                sublabel="Tell the crew what you love about this activity."
                v-model="activity.text"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                placeholder="This looks like a fun thing to do! Is anyone interested..."
                background-color="#fafafa"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <j-chip-group
            label="Event Type*"
            :items="activityTypes"
            @input="activity.type = $event"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-text-field
              label="Name of Place*"
              v-model="activity.nameOfPlace"
              placeholder="Where is the event located?"
              background-color="#fafafa"
              custom-classes="align-left"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="activity.linkUrl">
              <j-text-field
                label="Link"
                v-model="linkUrl"
                placeholder="Paste the link where your crew can find out more"
                background-color="#fafafa"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                custom-classes="align-left"
                :loading="metadataPending"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-textarea
              v-model="activity.note"
              placeholder="Note any costs, things to bring, how to register, etc..."
              background-color="#fafafa"
              label="Note"
            />
          </v-col>
        </v-row>
        <span class="tw-hidden">{{ invalid }}</span>
      </validation-observer>
    </v-form>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import { ACTIVITY_TYPES } from "@/enums/activities.js";
export default {
  name: "SuggestActivity",
  data() {
    return {
      activity: {
        name: "",
        description: "",
        text: "",
        type: null,
        nameOfPlace: "",
        linkUrl: "",
        note: "",
        tripId: this.$route.params.id,
        authorId: null,
        // TODO: getting a dupe key eslint violation for the text key. Might be because it's being set by the text-field as well as set to an empty string here but bypassing for now.
        // eslint-disable-next-line
        text: ""
      },
      activityTypes: ACTIVITY_TYPES,
      linkUrl: null,
      metadata: null,
      metadataPending: false
    };
  },
  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    },
    linkUrl(val) {
      this.fetchMetadata(val);
      this.activity.linkUrl = val;
    }
  },
  computed: {
    enable() {
      //TODO: Add check for eventType
      return (
        this.activity.nameOfPlace.length > 0 &&
        this.activity.name.length > 0 &&
        this.activity.text.length > 0 &&
        this.activity.type
      );
    }
  },
  methods: {
    ...mapMutations(["meta/SET_NAVIGATION_BUTTON"]),
    setNavigationButton(options) {
      this["meta/SET_NAVIGATION_BUTTON"](options);
    },
    async save() {
      this.setNavigationButton({ text: "Posting" });
      try {
        await this.$store.dispatch("post-activity/create", this.activity);
        setTimeout(() => {
          this.setNavigationButton({ text: "Post" });
          this.$router.push({
            name: "ActivitiesRoute",
            params: { id: this.$route.params.id }
          });
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMetadata(url) {
      if (this.checkValidUrl(url)) {
        this.metadataPending = true;
        await this.$store
          .dispatch("metascraper/create", { url })
          .then((res) => {
            if (res) {
              this.metadata = res;
              this.metadataPending = false;
              if (this.metadata.description)
                this.activity.text =
                  this.activity.text.length > 0
                    ? this.activity.text
                    : this.metadata.description;
              if (this.metadata.title && this.metadata.title.length < 200)
                this.activity.name = this.metadata.title;
              if (this.metadata.url) this.activity.linkUrl = this.metadata.url;
              if (this.metadata.image)
                this.activity.imageUrl = this.metadata.image.url;
            }
          })
          .catch((err) => {
            this.metadataPending = false;
            console.log(err);
          });
      }
    },
    checkValidUrl(url) {
      try {
        new URL(url);
      } catch (_) {
        return false;
      }
      return true;
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "THINGS TO DO",
      bgColor: "#0036F5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.setNavigationButton({
      button: true,
      text: "Post",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
    this.activity.tripId = this.$route.params.id;
    this.activity.authorId = this.$store.state.auth.user.id;
  }
};
</script>
